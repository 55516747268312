import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import Login from '@shared/components/login';
import Modal from '@shared/components/modal';
import { LoggedInContext } from '@shared/context';
import Button from '@shared/components/button';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#eee',
  },
}));

const PrivateComponent = ({ children }) => {
  const classes = useStyles();
  const { loggedIn } = useContext(LoggedInContext);
  const [isOpen, setIsOpen] = useState(false);

  const handleLoginClick = () => {
    setIsOpen(() => true);
  };
  const handleOnCloseClick = () => {
    setIsOpen(() => false);
  };

  return loggedIn ? (
    <React.Fragment>{children}</React.Fragment>
  ) : (
    <div className={`p-6 text-center ${classes.root}`}>
      <h6>Protected content. Please sign in to access</h6>
      <Button onClick={handleLoginClick} className="bg-primary text-white">
        Log in
      </Button>
      <Modal title="Sign in" open={isOpen} canClose={true} onCloseClick={handleOnCloseClick}>
        <Login />
      </Modal>
    </div>
  );
};

PrivateComponent.propTypes = {
  children: PropTypes.any.isRequired,
};

export default PrivateComponent;
