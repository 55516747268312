import React from 'react';
import PrivateComponent from '@shared/components/private-component';
import DonateComponent from '@shared/components/donate-component';
import Layout from '@org/components/layout';
import Seo from '@org/components/seo';

const Donate = () => {
  return (
    <Layout>
      <Seo title="Donate" />
      <PrivateComponent>
        <DonateComponent />
      </PrivateComponent>
    </Layout>
  );
};

export default Donate;
